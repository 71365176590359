body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.top-bar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: #333;
	padding: 10px;
	color: #fff;
}

.logo {
	font-size: 1.5em;
	font-weight: bold;
	@media (max-width: 600px) {
		font-size: 0.7em;
	}
}

.nav-links a.home-link {
	font-weight: bold;
	color: #fff;
	text-decoration: none;
	@media (max-width: 600px) {
		font-size: 0.5em;
	}
}

.nav-links {
	display: flex;
	gap: 40px;
	position: relative;
	align-items: center;
}

.menu-icon {
	cursor: pointer;
	display: flex;
	flex-direction: column;
}

.bar {
	height: 4px;
	width: 50px;
	background-color: #fff;
	margin: 6px 0;
	transition: 0.4s;
	@media (max-width: 600px) {
		height: 2px;
		width: 30px;
		margin: 3px 0;
	}
}

.menu {
	position: absolute;
	top: 100%;
	right: 0;
	background: rgba(0, 0, 0, 0.5);
	padding: 8px;
	display: flex;
	flex-direction: column;
	gap: 10px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
	backdrop-filter: blur(4px);
	@media (max-width: 600px) {
		font-size: 0.6em;
	}
}

.menu a {
	color: #fff;
	text-decoration: none;
	@media (max-width: 600px) {
		font-size: 0.6em;
	}
}

.menu a:hover {
	font-weight: bold;
}

.container {
	max-width: 900px;
	margin: 20px auto;
	padding: 20px;
	background-color: #f9f9f9;
	border-radius: 8px;
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
	box-sizing: border-box;
}

.container-1 {
	width: 90%;
	margin: 20px auto;
	padding: 20px;
	background-color: #f9f9f9;
	border-radius: 8px;
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
	box-sizing: border-box;
}

.blog-intro {
	max-width: 800px;
	margin: 20px auto;
	padding: 20px;
	background-color: #f9f9f9;
	border-radius: 8px;
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
	box-sizing: border-box;
	text-align: center;
}

.blog-intro h2 {
	@media (max-width: 600px) {
		font-size: 1.2rem;
	}
}

.blog-title {
	font-size: 2.5rem;
	margin-bottom: 20px;
	color: #333;
	@media (max-width: 600px) {
		font-size: 1.5rem;
	}
}

.blog-description {
	font-size: 1.2rem;
	margin-bottom: 20px;
	color: #555;
	@media (max-width: 600px) {
		font-size: 0.7rem;
	}
}

.blog-content {
	font-size: 1.1rem;
	color: #666;
	line-height: 1.6;
	@media (max-width: 600px) {
		font-size: 0.6rem;
	}
}

.container-tiles {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	gap: 20px;
}

.product-container {
	border: 1px solid #ddd;
	padding: 20px;
	margin: 0;
	border-radius: 12px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	background-color: #fff;
	width: 80%;
}

.product-title {
	font-size: 1.8rem;
	margin-bottom: 12px;
	color: #333;
}

.product-image {
	max-width: 100%;
	height: auto;
	border-radius: 8px;
}

.product-description {
	color: #666;
}

.top-picks-tile {
	border: 1px solid #ddd;
	padding: 20px;
	margin: 3%;
	border-radius: 12px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	background-color: #fff;
	transition: transform 0.3s ease-in-out;
	overflow: hidden;
}

.top-picks-tile h3 {
	@media (max-width: 600px) {
		font-size: 0.9rem;
	}
}

.top-picks-title {
	font-size: 1.8rem;
	margin-bottom: 12px;
	color: #333;
	@media (max-width: 460px) {
		font-size: 1.3rem;
	}
}

.top-picks-image {
	max-width: 50%;
	height: auto;
	border-radius: 8px;
	@media (max-width: 460px) {
		max-width: 70%;
	}
}

.top-picks-description {
	color: #666;
	font-size: 1em;
	@media (max-width: 700px) {
		font-size: 0.7em;
	}
	@media (max-width: 460px) {
		font-size: 0.3em;
	}
}

.top-picks-tile a {
	display: inline-block;
	padding: 10px 16px;
	background-color: #4caf50;
	color: #fff;
	text-decoration: none;
	border-radius: 4px;
	transition: background-color 0.3s ease-in-out;
	@media (max-width: 460px) {
		padding: 5px 10px;
		font-size: 0.7rem;
	}
}

.top-picks-tile a:hover {
	background-color: #45a049;
}

.header {
	color: #333;
	text-align: center;
	font-size: 2.5rem;
	margin-bottom: 20px;
	@media (max-width: 600px) {
		font-size: 1.5rem;
	}
}

.description {
	color: #555;
	text-align: center;
	font-size: 1.2rem;
	margin-bottom: 30px;
	@media (max-width: 600px) {
		font-size: 0.7rem;
	}
}

.category-section {
	margin-top: 50px;
}

.category-title {
	color: #333;
	font-size: 2rem;
	margin-bottom: 10px;
	@media (max-width: 600px) {
		font-size: 1rem;
	}
}

.category-list {
	list-style-type: none;
	padding: 10%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	@media (max-width: 900px) {
		padding: 5%;
	}
}

.category-item {
	flex: 0 0 calc(33.333% - 20px);
	margin-bottom: 30px;
	background-color: #fff;
	padding: 20px;
	border-radius: 8px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	@media (max-width: 900px) {
		padding: 5%;
		width: 200%;
	}
	@media (max-width: 740px) {
		flex: 0 0 calc(100% - 20px);
		width: 200px;
	}
}

.category-link {
	color: #007bff;
	text-decoration: none;
	font-weight: bold;
	font-size: 1.3rem;
	transition: color 0.3s ease-in-out;
}

.category-link:hover {
	color: #0056b3;
	text-decoration: underline;
}

.overview-image {
	max-width: 100%;
	height: auto;
	border-radius: 8px;
	@media (max-width: 600px) {
		max-width: 50%;
	}
}

.overview-title {
	font-size: 1.6rem;
	margin-bottom: 12px;
	color: #333;
	@media (max-width: 600px) {
		font-size: 1rem;
	}
}

.button-link {
	display: inline-block;
	padding: 10px 20px;
	background-color: #4caf50;
	color: #fff;
	text-decoration: none;
	border-radius: 5px;
	font-weight: bold;
	transition: background-color 0.3s ease-in-out;
	@media (max-width: 600px) {
		padding: 5px 10px;
		font-size: 0.7rem;
	}
}

.button-link:hover {
	background-color: #45a049;
}

.pros-cons-table {
	display: flex;
	justify-content: space-around;
	margin-top: 10px;
}

.pros,
.cons {
	flex: 1;
	border: 1px solid black;
}

.pros h3,
.cons h3 {
	font-size: 1.2em;
	text-align: center;
	@media (max-width: 700px) {
		font-size: 0.8em;
	}
	@media (max-width: 400px) {
		font-size: 0.5em;
	}
}

.pros ul,
.cons ul {
	list-style-type: none;
	padding: 0;
}

.pros li,
.cons li {
	padding: 5%;
	margin-bottom: 10px;
	font-size: 1em;
	@media (max-width: 700px) {
		font-size: 0.8em;
	}
	@media (max-width: 400px) {
		font-size: 0.5em;
	}
}

.pros li::before {
	content: "✔️ ";
	color: #4caf50;
	font-size: 1.1em;
	@media (max-width: 700px) {
		font-size: 0.8em;
	}
	@media (max-width: 400px) {
		font-size: 0.6em;
	}
}

.cons li::before {
	content: "❌ ";
	color: #ff0000;
	font-size: 1em;
	@media (max-width: 700px) {
		font-size: 0.7em;
	}
	@media (max-width: 400px) {
		font-size: 0.4em;
	}
}
